<template>
  <!-- eslint-disable -->
  <v-container id="rfp-page" fluid tag="section">
    <v-row justify="start">
      <v-col cols="12">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Country Subscriber View
            </div>
            <div class="
                d-flex
                flex-row
                align-center
                justify-start
                subtitle-1
                font-weight-light
              ">
              <span>This shows the Country Subscriber profile</span>
            </div>
          </template>
          <div>
            <v-overlay :value="overlay" opacity="1" color="white" z-index="0" absolute="absolute">
              <v-progress-circular indeterminate size="100" color="primary">
              </v-progress-circular>
            </v-overlay>
          </div>
          <v-card-text>
            <v-row>
              <v-col cols="8">
                <v-row class="d-flex flex-row align-start justify-space-between">
                  <!-- country details details -->
                  <v-col cols="12" class="px-0">
                    <v-row class="px-0">
                      <v-col cols="12">
                        <h3 class="headline text-left">Country Details</h3>
                      </v-col>
                    </v-row>
                    <!-- individual row for country Name details -->
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Country Name:</span>
                      </v-col>
                      <v-col cols="8">
                        <span class="subtitle-1 text-left" v-if="countryName">{{
              countryName
            }}</span>
                        <span class="subtitle-1 text-left platinum--text text--darken-3" v-else>n/a</span>
                      </v-col>
                    </v-row>
                    <!-- end of individual row for country Name details -->
                    <!-- individual row for Website details -->
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">City's Official Website:</span>
                      </v-col>
                      <v-col cols="8">
                        <v-tooltip bottom v-if="website">
                          <template v-slot:activator="{ on }">
                            <span class="subtitle-1 text-left" v-on="on">
                              <a :href="validateUrl(website)" target="_blank">{{
              validateUrl(website)
            }}</a>
                            </span>
                          </template>
                          <span>
                            Visit
                            <b>
                              <i>{{ validateUrl(website) }}</i>
                            </b>
                          </span>
                        </v-tooltip>
                        <span class="subtitle-1 text-left platinum--text text--darken-3" v-else>n/a</span>
                      </v-col>
                    </v-row>
                    <!-- end of individual row for Website details -->
                    <!-- individual row for Type of Representation details -->
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Type of Representation:</span>
                      </v-col>
                      <v-col cols="8">
                        <span class="subtitle-1 text-left" v-if="type">{{
              type
            }}</span>
                        <span class="subtitle-1 text-left platinum--text text--darken-3" v-else>n/a</span>
                      </v-col>
                    </v-row>
                    <!-- end of individual row for Type of Representation details -->
                    <!-- individual row for Proof of Representation details -->
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Proof of Representation:</span>
                      </v-col>
                      <v-col cols="8">
                        <v-chip class="proof-link ma-0 px-4" color="secondary" label text-color="white" :href="proof[0]"
                          target="_blank" v-if="proof[0]">
                          <span class="subtitle-1">Download</span>
                          <v-icon right>mdi-file-download</v-icon>
                        </v-chip>
                        <span class="subtitle-1 text-left platinum--text text--darken-3" v-else>n/a</span>
                      </v-col>
                    </v-row>
                    <!-- end of individual row for Proof of Representation details -->
                    <!-- individual row for Office Address details -->
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Country Office Address:</span>
                      </v-col>
                      <v-col cols="8">
                        <span v-if="country" class="subtitle-1 text-left">{{
              `${street ? street : ""} ${postalCode ? `${postalCode}` : ""
              } ${city ? ` ${city}, ` : ""}${country ? `${country}` : ""
              }`
            }}</span>
                        <span class="subtitle-1 text-left platinum--text text--darken-3" v-else>n/a</span>
                      </v-col>
                    </v-row>
                    <!-- end of individual row for Office Address details -->
                    <!-- individual row for Social Media details -->
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Social Media Channels:</span>
                      </v-col>
                      <v-col cols="8">
                        <v-row class="d-flex flex-row flex-wrap mx-0" v-if="socialMedia.length > 0">
                          <v-col v-for="socialMedia in socialMedia" :key="socialMedia._id" v-show="socialMedia.link"
                            class="py-0 pl-0 flex-grow-0">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-chip v-on="on" class="mr-2 mb-2" label :href="validateUrl(socialMedia.link)"
                                  target="_blank" :color="getSocialColor(socialMedia.name)">
                                  <font-awesome-icon :icon="['fab', socialMedia.icon]" class="mr-2" size="2x"
                                    color="white" />
                                  <span class="
                                  text-left
                                  caption
                                  text-capitalize
                                  white--text
                                ">{{ socialMedia.name }}</span>
                                </v-chip>
                              </template>
                              <span>{{ validateUrl(socialMedia.link) }}</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                        <v-row class="d-flex flex-row mx-0" v-else>
                          <span class="
                          subtitle-1
                          text-left
                          platinum--text
                          text--darken-3
                        ">n/a</span>
                        </v-row>
                      </v-col>
                    </v-row>
                    <!-- end of individual row for Social Media details -->
                    <!-- individual row for Main topic details -->
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Main topic country specializes in:</span>
                      </v-col>
                      <v-col cols="8" v-if="topics.length > 0">
                        <v-chip class="mr-2 grey--text" label v-for="(item, index) in topics" :key="index">{{ item }}
                        </v-chip>
                      </v-col>
                      <v-col cols="8" v-else>
                        <v-row class="mx-0">
                          <span class="
                          subtitle-1
                          text-left
                          platinum--text
                          text--darken-3
                        ">n/a</span>
                        </v-row>
                      </v-col>
                    </v-row>
                    <!-- end of individual row for Main topic details -->
                    <!-- individual row for Subtopics details -->
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Subtopics country specializes in:</span>
                      </v-col>
                      <v-col cols="8" v-if="subTopics">
                        <v-row class="d-flex flex-row flex-wrap mx-0" v-if="subTopics.length > 1">
                          <v-chip class="mr-2 mb-2 grey--text" label :key="index"
                            v-for="(subTopics, index) in subTopics">{{
              subTopics }}
                          </v-chip>
                        </v-row>
                        <v-row class="mx-0" v-else>
                          <span class="
                          subtitle-1
                          text-left
                          platinum--text
                          text--darken-3
                        ">n/a</span>
                        </v-row>
                      </v-col>
                      <v-col cols="8" v-else>
                        <v-row class="mx-0">
                          <span class="
                          subtitle-1
                          text-left
                          platinum--text
                          text--darken-3
                        ">n/a</span>
                        </v-row>
                      </v-col>
                    </v-row>
                    <!-- end of individual row for Subtopics details -->
                  </v-col>
                  <!-- country rep. details -->
                  <v-col cols="12" class="px-0 mt-5">
                    <v-row class="px-0">
                      <v-col cols="12">
                        <h3 class="headline text-left">
                          Country (Representative) Details
                        </h3>
                      </v-col>
                    </v-row>
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Name:</span>
                      </v-col>
                      <v-col cols="8">
                        <span class="subtitle-1 text-left" v-if="name">{{
              name
            }}</span>
                        <span class="subtitle-1 text-left platinum--text text--darken-3" v-else>n/a</span>
                      </v-col>
                    </v-row>
                    <!-- end of individual row for Name details -->
                    <!-- individual row for Email details -->
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Email:</span>
                      </v-col>
                      <v-col cols="8">
                        <v-tooltip bottom v-if="email">
                          <template v-slot:activator="{ on }">
                            <span class="subtitle-1 text-left" v-on="on">
                              <a :href="`mailto:${email}`">{{ email }}</a>
                            </span>
                          </template>
                          <span>
                            Send an email to
                            <b>{{ name }}</b> via
                            <b>
                              <i>{{ email }}</i>
                            </b>
                          </span>
                        </v-tooltip>
                        <span class="subtitle-1 text-left platinum--text text--darken-3" v-else>n/a</span>
                      </v-col>
                    </v-row>
                    <!-- end of individual row for Email details -->
                    <!-- individual row for Phone details -->
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Phone:</span>
                      </v-col>
                      <v-col cols="8">
                        <v-tooltip bottom v-if="telephoneNo && telephoneNo.no">
                          <template v-slot:activator="{ on }">
                            <span class="subtitle-1 text-left" v-on="on">
                              <a :href="`tel:+${telephoneNo.country + telephoneNo.no
              }`">{{
              `+${telephoneNo.country} ${telephoneNo.no}`
            }}</a>
                            </span>
                          </template>
                          <span>
                            Call
                            <b>{{ name }}</b> via
                            <b>
                              <i>{{
              `+${telephoneNo.country} ${telephoneNo.no}`
            }}</i>
                            </b>
                          </span>
                        </v-tooltip>
                        <span class="subtitle-1 text-left platinum--text text--darken-3" v-else>n/a</span>
                      </v-col>
                    </v-row>
                    <!-- end of individual row for Phone details -->
                    <!-- individual row for Joined Date details -->
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Joined Date:</span>
                      </v-col>
                      <v-col cols="8">
                        <span class="subtitle-1 text-left" v-if="JoinedDate">{{
              JoinedDate
            }}</span>
                        <span class="subtitle-1 text-left platinum--text text--darken-3" v-else>n/a</span>
                      </v-col>
                    </v-row>
                    <!-- end of individual row for Joined Date details -->
                  </v-col>
                  <!-- country payment details -->
                  <v-col cols="12" class="px-0 mt-5">
                    <v-row class="px-0">
                      <v-col cols="12">
                        <h3 class="headline text-left">Country Payment Details</h3>
                      </v-col>
                    </v-row>
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Country Approval State:</span>
                      </v-col>
                      <v-col cols="8">
                        <span class="subtitle-1 text-left success--text" v-if="isApproved">Approved</span>
                        <span class="subtitle-1 text-left warning--text" v-else>Pending/Rejected</span>
                      </v-col>
                    </v-row>
                    <!-- end of individual row for Approved details -->
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4">
                <v-row class="d-flex flex-row align-start justify-space-between">
                  <!-- country details details -->
                  <v-col cols="12" class="px-0">
                    <v-row class="px-0">
                      <v-col cols="12">
                        <h3 class="headline text-left" style="margin-top: 20px;margin-bottom: 20px;">Sustainability Certificates</h3>
                      </v-col>
                    </v-row>
                    <v-divider style="margin-bottom: 40px;"></v-divider>
                    <v-row class="px-0" v-for="(url, index) in sustainabilityDocs" :key="url" style="margin-top: -20px;">
                      <v-col cols="10">{{ index+1 }} :- <a
                          :href="`https://conferli-storage.s3.amazonaws.com/sustainabilityCertificates/` + url"
                          target="_blank">{{ url }}</a></v-col>
                      <v-col cols="2">
                        <v-btn icon color="red lighten-1" @click="removeCertificate(index)">
                          <font-awesome-icon :icon="['fas', 'trash']" />
                        </v-btn>
                      </v-col>
                    </v-row>
                    <h6 class="text-left" v-if="sustainabilityDocs.length < 1" style="margin-top: 25px;">Not uploaded yet!</h6>
                  </v-col>
                  <v-col cols="12" class="px-0">
                    <v-row class="px-0">
                      <v-col cols="12">
                        <h3 class="headline text-left">
                          Update Representative Account
                        </h3>
                        <v-divider></v-divider>
                        <v-row class="px-0">
                          <v-col cols="10" style="margin-top: 20px">
                            <v-text-field style="margin-bottom: -40px" type="text" label="Country Subscriber Name"
                              outlined v-model="newName"></v-text-field>
                          </v-col>
                          <v-col cols="10">
                            <v-text-field style="margin-bottom: -40px" type="text" label="Email" outlined
                              v-model="newEmail"></v-text-field>
                          </v-col>
                          <v-col cols="10">
                            <v-text-field style="margin-bottom: -40px" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="show ? 'text' : 'password'" v-model="password" label="New Password" outlined
                              @click:append="show = !show" :error-messages="errorPassword"></v-text-field>
                          </v-col>
                          <v-col cols="10">
                            <v-btn block style="background-color: #35a7ff;" @click="dialog = true">
                              <v-icon left dark>
                                mdi-card-account-details
                              </v-icon>
                              Update Representative Account
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card height="45vh" style="overflow-y: scroll;padding-right: 10px;padding-left: 10px;padding-top: 20px">
        <v-card-title class="px-2 px-sm-6">
          <v-row class="pb-3">
            <v-col cols="8" lg="10" class="d-flex flex-column justify-center align-start py-0">
              <h2 class="headline text-left">Please confirm</h2>
            </v-col>
            <v-col cols="4" lg="2" class="d-flex flex-column justify-center align-end py-0">
              <v-btn class="dialog-close-btn" icon color="grey lighten-1" @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row v-if="email !== newEmail || name !== newName || password !== ''">
            <v-col cols="6" md="6">
              <span class="text-left caption text-capitalize red--text">Current</span>
              <br />
              <span class="text-left caption text-capitalize black--text" v-if="name !== newName">Name :- {{ name
                }}</span>
              <v-divider></v-divider>
              <span class="text-left caption text-capitalize black--text" v-if="email !== newEmail">Email :- {{
              email
            }}</span>
              <v-divider></v-divider>
              <span class="text-left caption text-capitalize black--text" v-if="password !== ''">Password :-
                hidden</span>
            </v-col>
            <v-col cols="6" md="6">
              <span class="text-left caption text-capitalize green--text">New</span>
              <br />
              <span class="text-left caption text-capitalize black--text" v-if="name !== newName">Name :- {{ newName
                }}</span>
              <v-divider></v-divider>
              <span class="text-left caption text-capitalize black--text" v-if="email !== newEmail">Email :- {{
              newEmail
                }}</span>
              <v-divider></v-divider>
              <span class="text-left caption text-capitalize black--text" v-if="password !== ''">Password :- {{
                password === '' ? 'Not Changed!' : password
                }}</span>
            </v-col>
          </v-row>
          <v-row v-else style="text-align: center">
            <span style="text-align: center;width: 100%;margin-top: 10vh"
              class="text-center caption text-capitalize grey--text font-weight-bold">There is no changes to
              update!</span>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-6" v-if="email !== newEmail || name !== newName || password !== ''">
          <v-row class="d-flex flex-column-reverse flex-sm-row align-center justify-space-between mx-0">
            <v-col cols="12" sm="3" class="d-flex flex-column align-center align-sm-start px-0">
              <v-btn class="px-8" color="secondary" @click="dialog = false" tile outlined depressed bottom>
                Cancel
              </v-btn>
            </v-col>
            <v-col cols="12" sm="3" class="d-flex flex-column align-center align-sm-end px-0"
              v-if="email !== newEmail || name !== newName || password !== ''">
              <v-btn class="px-8 mx-0" color="secondary" tile depressed bottom right @click="updateProfile">
                Confirm
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import countrySubscribersApicalls from "../../../services/countrySubscribers.service";
import Apicalls from "../../../services/login.service";

export default {
  name: "CountrySubView",
  data() {
    return {
      dialog: false,
      details: null,
      isAPILoading: false,
      countryName: null,
      countrySubscriberId: null,
      website: "",
      type: null,
      proof: [],
      address: null,
      street: null,
      postalCode: null,
      city: null,
      country: null,
      socialMedia: [],
      topics: [],
      subTopics: [],
      name: null,
      email: null,
      telephoneNo: null,
      JoinedDate: null,
      isApproved: false,
      overlay: true,
      errorPassword: null,
      password: null,
      newEmail: null,
      newName: null,
      show: false,
      staticErrorMessages: {
        email: {
          isEmpty: "Email Address cannot be empty"
        },
        password: {
          isEmpty: "Password cannot be empty"
        }
      },
      sustainabilityDocs: [],
      sustainabilityDocLinks: [],
      profile: '',
      code: ''
    };
  },
  computed: {},
  async mounted() {
    this.countrySubscriberId = this.$route.params.id;
    await this.initFn(this.countrySubscriberId);
    this.overlay = false;
  },
  methods: {
    async removeCertificate(index) {
      const payload = {
        profile: this.code,
        url: this.sustainabilityDocLinks[index]
      }
      await countrySubscribersApicalls.deleteSustainabilityCertificate(payload);
      await this.initFn(this.countrySubscriberId);
    },
    async initFn(countrySubscriberId = this.$route.params.id) {
      this.isAPILoading = true;
      let { data, status } =
        await countrySubscribersApicalls.getSubscriberDetailsById({
          countrySubscriberId,
          countryDetails: true,
        });
      if (data && status == 200) {
        let details = data.data;

        this.countryName = details.countryName;
        this.website = details.website;
        this.type = details.type;
        this.proof = details.proof;

        // this.address = {
        //   city: details.address?.city,
        //   country: details.address?.country,
        //   postalCode: details.address?.postalCode,
        //   street: details.address?.street,
        // };

        this.socialMedia = details.socialMedia;

        this.topics = details.maintopics;
        this.subTopics = details.subTopics;
        this.name = details.name;
        this.newName = details.name;
        this.email = details.email;
        this.newEmail = details.email;
        this.password = '';
        this.telephoneNo = {
          code: details.telephoneNo?.code,
          country: details.telephoneNo?.country,
          no: details.telephoneNo?.no,
        };

        (this.city = details.address?.city),
          (this.country = details.address?.country),
          (this.postalCode = details.address?.postalCode),
          (this.street = details.address?.street),
          (this.JoinedDate = details.created_at.split("T")[0]),
          (this.isApproved = details.isApproved);

        this.sustainabilityDocLinks = []
        this.sustainabilityDocs = []
        details.sustainabilityCertificate.forEach(element => {
          try {
            this.sustainabilityDocLinks.push(element);
            this.sustainabilityDocs.push(decodeURIComponent(element.split("sustainabilityCertificates/")[1].replace(/%20/g, ' ').replace(/%28/g, '(').replace(/%29/g, ')')))
          } catch (e) {
            console.log('sus certificate replace err')
          }
        });
        this.code = details.code;
      }
    },
    getSocialColor(name) {
      if (name == "LinkedIn") {
        return "#0a66c2";
      } else if (name == "Twitter") {
        return "#1da1f2";
      } else if (name == "Youtube") {
        return "#ff0000";
      } else if (name == "Instagram") {
        return "#f00075";
      } else if (name == "Vimeo") {
        return "#00adef";
      } else if (name == "Flickr") {
        return "#ff0084";
      } else {
        return "secondary";
      }
    },
    validateUrl(url) {
      if (!url) return;
      let newUrl = window.decodeURIComponent(url);
      newUrl = newUrl.trim().replace(/\s/g, "");
      if (/^(:\/\/)/.test(newUrl)) {
        return `https${newUrl}`;
      }
      if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `https://${newUrl}`;
      }
      return newUrl;
    },
    updateProfile() {
      var input = document.createElement('input');
      input.type = 'email';
      input.required = true;
      input.value = this.email.toLowerCase();
      if (!/\S+@\S+\.\S+/.test(this.email.toLowerCase())) {
        let payload2 = {
          snackBarText: `Given email address is not valid!`,
          snackBarColor: "error",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload2);
      } else {
        const payload = {
          oldEmail: this.email,
          newEmail: this.newEmail,
          newName: this.newName,
          password: this.password
        }
        countrySubscribersApicalls.updateAccount(payload).then((res) => {
          console.log(res)
          Apicalls.getDetails({ email: this.email })
            .then(() => {
              this.initFn(this.countrySubscriberId);
            })
            .catch();
          this.dialog = false;
          let payload2 = {
            snackBarText: `Representative Account Updated!`,
            snackBarColor: "success",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
        }).catch(() => {
          let payload2 = {
            snackBarText: `Something went wrong! \nPlease try again.`,
            snackBarColor: "error",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          this.dialog = false;
        });
      }
    }
  },
};
</script>
<style scoped>
.proof-link {
  cursor: pointer;
}
</style>
