import instance from "@/services/instance.js";

const GetAllCountrySubscribers = (params) => {
  return instance.instanceToken
    .get("/countrySubscriber", {params})
};

const SetApproval = (countrySubscriberId, payload) => {
  return instance.instanceToken
    .post(`/countrySubscriber/approve/${countrySubscriberId}`, payload)
};

const GetSubscriberDetailsById = (payload) => {
  return instance.instanceToken
    .get(`/countrySubscriber/${payload.countrySubscriberId}`, {params: {countryDetails: payload.countryDetails}})
};

const SetDelete = countrySubscriberId => {
  return instance.instanceToken
    .delete(`/countrySubscriber/${countrySubscriberId}`)
};

const SetBillingDate = (countrySubscriberId, payload) => {
  return instance.instanceToken
    .post(`/countries/billingDate/${countrySubscriberId}`, payload)
};
const UpdateAccount = payload => {
  return instance.instanceToken
    .post("/countries/admin_update", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const DeleteSustainabilityCertificate= (payload) => {
  return instance.instanceToken
    .delete("/supportDocuments/delete_sustainability_certificate", {
      data: payload
    })
};
export default {
  getAllCountrySubscribers: GetAllCountrySubscribers,
  setApproval: SetApproval,
  getSubscriberDetailsById: GetSubscriberDetailsById,
  setDelete: SetDelete,
  setBillingDate: SetBillingDate,
  updateAccount: UpdateAccount,
  deleteSustainabilityCertificate:DeleteSustainabilityCertificate
};
